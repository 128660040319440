@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

html,
body {
  min-height: 100%;
}

#__next {
  height: 100%;
}

:root {
  --flo-primary-dark-color: #01cca7;
  --flo-primary-light-color: #8fffeb;
  --flo-coal-dark-color: #4d4d4d; /* for texts */
  --flo-coal-light-color: #6e6e6e; /* for texts */
  --flo-coal-lighter-color: #e0e0e0;
  --flo-carbon-color: #1a1a1a;
  --flo-gray-dark-color: #949494;
  --flo-gray-light-color: #f0f0f0; /* for borders */
  --flo-gray-lighter-color: #f5f5f5; /* for background */
  --flo-gray-lightest-color: #fafafa;
  --flo-gray-faintest-color: #f9f9f9;
  --flo-gray-medium-color: #b0b0b0;
  --flo-coal-slate-color: #61636a;
  --flo-coal-medium-color: #515151;

  --flo-yellow-dark-color: #9c7309;
  --flo-yellow-light-color: #c7981f;
  --flo-yellow-lighter-color: #c7981f66;
  --flo-red-dark-color: #cb2711;
  --flo-green-dark-color: #2c874a;
  --flo-orchid-color: #a34242;
  --flo-green-light-color: #01a386;

  --flo-yay-color: #2c874a; /* for success */
  --flo-yay-light-color: #eaf3ed; /* for success gradiant */
  --flo-yay-lighter-color: #f4f9f6; /* for success gradiant */

  --flo-lavender-dark-color: #4b4a9c;
  --flo-orchid-dark-color: #a34242;
  --flo-orchid-light-color: #e49b9b33;

  --flo-border-width-0: 0;
  --flo-border-width-1: 1px;
  --flo-border-width-2: 2px;
  --flo-border-width-3: 3px;
  --flo-border-width-4: 4px;
  --flo-border-width-6: 6px;
  --flo-border-width-8: 8px;
  --flo-border-width-12: 12px;

  --flo-border-radius-none: 0;
  --flo-border-radius-xs: 0.125rem;
  --flo-border-radius-sm: 0.25rem;
  --flo-border-radius-md: 0.375rem;
  --flo-border-radius-lg: 0.5rem;
  --flo-border-radius-xl: 0.75rem;
  --flo-border-radius-2xl: 1rem;
  --flo-border-radius-3xl: 1.5rem;
  --flo-border-radius-4xl: 2.5rem;
}

@layer utilities {
  .settings_list-status_yellow {
    background: linear-gradient(0deg, rgba(239, 208, 128, 0.1), rgba(239, 208, 128, 0.1)), #ffffff;
  }
}

.RichEditor-editor {
  cursor: text;
  font-size: 16px;
  font-family: 'IBM Plex Mono', monospace;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #1a1a1a;
  font-family: 'IBM Plex Mono', monospace;
}

@font-face {
  font-family: 'IBM Plex Mono';
  src: url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@600');
}

.sketch-picker .flexbox-fix div {
  min-height: 24px;
  min-width: 24px;
}

.sketch-picker .flexbox-fix .hue-horizontal div {
  min-width: 4px;
}

.rewards-notactive_warning {
  border: 2px solid rgba(199, 152, 31, 0.4);
  background: linear-gradient(0deg, rgba(199, 152, 31, 0.1) 0%, rgba(199, 152, 31, 0.1) 100%), #fff;
}

.scrollbar-hide {
  /* IE and Edge */
  -ms-overflow-style: 'none';

  /* Firefox */
  scrollbar-width: 'none';

  /* Safari and Chrome */
  &::-webkit-scrollbar {
    display: 'none';
  }
}

.custom-scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    color: var(--flo-gray-lighter-color);
  }
  &::-webkit-scrollbar-track {
    background: var(--flo-gray-lighter-color);
    margin-top: 60px;
  }
  &::-webkit-scrollbar-thumb {
    background: #BDBDBD;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: var(--flo-coal-light-color);
  }
}
